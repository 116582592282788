import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Icons,
  Option,
  Select,
  Tooltip,
  Typography,
} from "@ster/ster-toolkit";
import { Divider } from "antd";
import { SelectValue } from "antd/lib/select";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Suspense, memo, useCallback, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";

import { forecastConversionGroupsState } from "../../atoms";
import { Medium, Order } from "../../types";
import styles from "./Forecast.module.less";
import ForecastRows from "./ForecastRows";

const Forecast = ({
  medium,
  order,
  onDeleteConversionGroup,
  onAddConversionGroup,
}: {
  medium: Medium;
  order: Order;
  onDeleteConversionGroup: (value: string) => void;
  onAddConversionGroup: (value: string) => void;
}): JSX.Element | null => {
  const conversionGroups = useRecoilValue(
    forecastConversionGroupsState(medium),
  );

  const [selectedConversionGroup, setSelectedConversionGroup] = useState<
    string | undefined
  >(undefined);

  const handleChangeConversionGroup = useCallback(
    (value: SelectValue | undefined) => {
      setSelectedConversionGroup(value as string);
    },
    [],
  );

  const handleAddConversionGroup = useCallback(() => {
    if (selectedConversionGroup) {
      onAddConversionGroup(selectedConversionGroup);
      setSelectedConversionGroup(undefined);
    }
  }, [onAddConversionGroup, selectedConversionGroup]);

  const subOrders = useMemo(
    () => [
      ...order.subOrders
        .filter((s) => s.calculated?.grp && s.calculated.grp > 0)
        .map(({ period, calculated, ...rest }) => ({
          ...rest,
          from: period[0],
          to: period[1],
          budget: calculated?.budget,
          grp: calculated?.grp,
          spotsPerDayChannel: calculated?.spotsPerDayChannel,
        })),
    ],
    [order.subOrders],
  );

  return (
    <section className={styles.forecast}>
      <div className={styles.forecastTitleWrapper}>
        <Typography.Title level={2}>Prognose</Typography.Title>
      </div>
      <div className={styles.forecastHeader}>
        <div className={styles.forecastCol}>
          <Icons.AdultsIcon width={40} height={40} fill="#fff" />
          <span className={styles.forecastCol__stat}>
            Doel
            <wbr />
            groep
          </span>
        </div>
        <div className={styles.forecastCol}>
          <Icons.CampaignIcon width={40} height={40} fill="#fff" />
          <span className={styles.forecastCol__stat}>Bereik in %</span>
        </div>
        <div className={styles.forecastCol}>
          <Icons.GroupIcon width={65} height={40} fill="#fff" />
          <span className={styles.forecastCol__stat}>Bereik in aantal</span>
        </div>
        <div className={styles.forecastCol}>
          <Icons.EyeIcon width={40} height={40} fill="#fff" />
          <span className={styles.forecastCol__stat}>
            GCF
            <Tooltip
              title={
                medium === "tv"
                  ? "Doorgaans adviseren wij een gemiddelde contact frequentie (GCF) tussen de 3 en 5 contacten per campagne."
                  : "Doorgaans adviseren we voor een actiematige radio campagne een gemiddelde contact frequentie (GCF) van 5-7 keer. Voor campagnes met een naamsbekendheid doelstelling is dit 8-10 keer."
              }
              placement="bottom"
            >
              <span>
                <Icons.QuestionIcon fill="white" width={16} height={16} />
              </span>
            </Tooltip>
          </span>
        </div>
        <div className={styles.forecastCol}>
          <Icons.StatsIcon width={40} height={40} fill="#fff" />

          <span className={styles.forecastCol__stat}>
            Bruto bereik in % (GRP)
          </span>
        </div>
      </div>
      <Divider className={styles.forecastDivider} />
      <Suspense
        fallback={
          <div className={styles.forecastRow}>
            <div className={`${styles.forecastCol} ${styles.loading}`}>
              <span className={styles.forecastCol__value}>
                <LoadingOutlined />
              </span>
            </div>
          </div>
        }
      >
        <ForecastRows
          medium={medium}
          subOrders={subOrders}
          conversionGroups={order.forecastConversionGroups}
          onDeleteConversionGroup={onDeleteConversionGroup}
        />

        <div className={styles.addConversionGroup}>
          <Select.Search
            placeholder="Kies een secundaire doelgroep"
            optionFilterProp="children"
            onSelect={handleChangeConversionGroup}
            value={selectedConversionGroup ?? []}
          >
            {conversionGroups
              ?.filter(
                (s) =>
                  order.forecastConversionGroups.find((x) => s.code === x) ===
                  undefined,
              )
              .map((conversionGroup) => (
                <Option
                  value={conversionGroup.code ?? ""}
                  key={conversionGroup.code ?? ""}
                >
                  {conversionGroup.displayName}
                </Option>
              ))}
          </Select.Search>
          <Button
            mode="tertiary"
            disabled={!selectedConversionGroup}
            onClick={handleAddConversionGroup}
          >
            <Icons.PlusIcon fill="#008ccc" />
            Voeg doelgroep toe
          </Button>
        </div>
      </Suspense>
    </section>
  );
};

export default memo(Forecast);
