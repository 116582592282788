/* tslint:disable */
/* eslint-disable */
/**
 * Ster.nl API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SterWebsiteModelPortalForecast
 */
export interface SterWebsiteModelPortalForecast {
  /**
   *
   * @type {string}
   * @memberof SterWebsiteModelPortalForecast
   */
  targetGroup?: string;
  /**
   *
   * @type {number}
   * @memberof SterWebsiteModelPortalForecast
   */
  bereikPercentage: number;
  /**
   *
   * @type {number}
   * @memberof SterWebsiteModelPortalForecast
   */
  gcf: number;
  /**
   *
   * @type {number}
   * @memberof SterWebsiteModelPortalForecast
   */
  bereikNumber?: number;
  /**
   *
   * @type {number}
   * @memberof SterWebsiteModelPortalForecast
   */
  grp: number;
}

export function SterWebsiteModelPortalForecastFromJSON(
  json: any,
): SterWebsiteModelPortalForecast {
  return SterWebsiteModelPortalForecastFromJSONTyped(json, false);
}

export function SterWebsiteModelPortalForecastFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SterWebsiteModelPortalForecast {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    targetGroup: !exists(json, "targetGroup") ? undefined : json["targetGroup"],
    bereikPercentage: json["bereikPercentage"],
    gcf: json["gcf"],
    bereikNumber: !exists(json, "bereikNumber")
      ? undefined
      : json["bereikNumber"],
    grp: json["grp"],
  };
}

export function SterWebsiteModelPortalForecastToJSON(
  value?: SterWebsiteModelPortalForecast | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    targetGroup: value.targetGroup,
    bereikPercentage: value.bereikPercentage,
    gcf: value.gcf,
    bereikNumber: value.bereikNumber,
    grp: value.grp,
  };
}
